import { Button, Form, Input, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState } from 'react';
import swal from "sweetalert";
import api from 'http';
import { configs } from 'config';
import { message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

function Add({ form, onFinish, st }) {

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);


    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const uploadButton = (
        <div style={{ width: "100%" }}>
            <CloudUploadOutlined />
            <p className="ant-upload-text">Format JPG, PNG, JPEG</p>
            <p className="ant-upload-hint">Faylni tanlash</p>
        </div>
    );


    return (
        <Form layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item name="ball" label="Ball miqdori">
                <Input type="number" />
            </Form.Item>
            <Form.Item name="btn_text" label="Tugma matni">
                <Input />
            </Form.Item>
            <Form.Item name="caption" label="Izoh">
                <TextArea maxLength={1024} />
            </Form.Item>
            <Form.Item name="image_path" label="Rasm">
                <ImgCrop
                    showGrid
                    rotationSlider
                    aspectSlider
                    modalCancel="Закрыть"
                    modalOk={"Добавить"}
                    modalTitle="Настройки изоброжения"
                    onModalOk={(file) => {
                        const formData = new FormData();
                        formData.append("images", file);
                        api
                            .post(configs.uploadImg, formData)
                            .then((json) => {
                                if (json.data.status === "success") {
                                    message.success("Rasm yuklandi");
                                    form.setFieldsValue({
                                        image_path: "https://test.visola-aziz.uz/api/" + json.data.data.image,
                                        duration: 3,
                                    });
                                } else
                                    swal({
                                        title: json.status,
                                        text: json.xabar,
                                        icon: "error",
                                        button: "Ок",
                                    });
                            })
                            .catch((err) => {
                                swal({
                                    title: "ERROR",
                                    text: `${err}`,
                                    icon: "error",
                                    button: "Ок",
                                });
                            });
                    }}
                >
                    <Upload
                        style={{ width: "100vw", zIndex: 99999 }}
                        action=""
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        progress
                    >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                </ImgCrop>
            </Form.Item>
            <Form.Item>
                <Button loading={st} type="primary" htmlType="submit">Qo'shish</Button>
            </Form.Item>
        </Form >
    );
}

export default Add;