import { Button, Image, message, Modal, Table } from 'antd';

import React, { useEffect, useState } from 'react';
import Add from './Add';
import { useForm } from 'antd/es/form/Form';
import api from 'http';

function BotProducts(props) {
    const [render, setRender] = useState(false);
    let [addbtnStatus, setAddBtnStatus] = useState(false)
    const handleDelete = (id) => {
        setAddBtnStatus(true)
        api.delete('/delete-gifts/' + id).then(res => {
            message.success('Sovg`a o`chirildi');
            setRender((s) => !s);
            setAddBtnStatus(false)
        });
    };
    const columns = [
        {
            title: "Sovg'a rasmi",
            dataIndex: 'image_path',
            key: 'image_path',
            render: (text, record) => {
                return <Image style={{ width: 60, height: 60, objectFit: 'cover' }} src={record.image_path} alt="" />;
            },
        },
        {
            title: "Ball miqdori",
            dataIndex: 'ball',
            key: 'ball',
        },
        {
            title: "Tugma matni",
            dataIndex: 'btn_text',
            key: 'btn_text',
        },
        {
            title: "Izoh",
            dataIndex: 'caption',
            key: 'caption',
        },
        {
            title: "Amal",
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Button type="primary" danger onClick={() => handleDelete(record.id)}>O`chirish</Button>
                    </div>
                );
            },
        }
    ];
    const [data, setData] = useState([]);
    const [form] = useForm();
    const [open, setOpen] = useState(false);

    const onFinish = (values) => {
        setAddBtnStatus(true)
        api.post('/add-gifts', values).then(res => {
            message.success('Sovg`a qo`shildi');
            setOpen(false);
            setRender(!render);
            setAddBtnStatus(false)
        });
    };
    useEffect(() => {
        console.log("render");
        api.get('/get-gifts').then(res => {
            setData(res.data);
        });
    }, [render]);


    return (
        <div>
            <Modal footer={null} title="Qo'shish" open={open} onCancel={() => setOpen(false)}>
                <Add st={addbtnStatus} form={form} onFinish={onFinish} />
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                <h1>Tanlov uchun sovg'alar</h1>
                <Button type="primary" onClick={() => setOpen(true)}>Qo'shish</Button>
            </div>
            <div>
                <Table rowKey="id" columns={columns} dataSource={data} />
            </div>
        </div>
    );
}

export default BotProducts;