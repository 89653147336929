import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import api from 'http';
import React, { useEffect, useState } from 'react';

function BotInfoBtn() {
    const [data, setData] = useState([]);
    let [form] = useForm();
    useEffect(() => {
       api.get("/get-bot-btns").then(function (res) {
            setData(res.data);
            form.setFieldsValue({
                id: res.data[0].id,
                btn_text: res.data[0].btn_text,
                caption: res.data[0].caption
            })
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    return (
        <Form form={form} onFinish={(values) => {
            api.put("/edit-bot-btn/" + values.id, values)
                .then(function (res) {
                    console.log(res);
                    message.success('Tugma tahrirlash muvaffaqiyatli');
                })
                .catch(function (error) {
                    console.log(error);
                    message.error('Tugma tahrirlashda xatolik');
                });
        }}>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="btn_text" label="Tugma matni">
                <Input />
            </Form.Item>
            <Form.Item name="caption" label="Tugma URL">
                <TextArea rows={10} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Saqlash</Button>
            </Form.Item>
        </Form>
    );
}

export default BotInfoBtn;