import { Button, Card, Col, Form, message, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import api from 'http';
import React, { useEffect, useState } from 'react';
import BotInfoBtn from './BotInfoBtn';

function BotMessage(props) {
    const [options, setOptions] = useState([]);
    const [shops, setShops] = useState([]);
    const [allShops, setAllShops] = useState(false);
    const handleChange = (value) => {
        console.log(value);
        setShops(value);
    };

    useEffect(() => {
        api.get("get-total-ball").then(function (res) {
            setOptions(res.data.map(item => ({
                value: item.id,
                label: item.name
            })));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    const handleShop = () => {
        setShops(options.map(item => item.value));
        setAllShops(true);
    }
    const handleClear = () => {
        setShops([]);
        setAllShops(false);
    }
    return (
        <div>
            <div>
                <Card title="Xabar yuborish" style={{marginBottom: 16, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'}}> 
                    <Form layout="vertical" onFinish={(values) => {
                        values.dukonlar = shops;
                        console.log(values);
                        api.post("admin-bot-send", values)
                            .then(function (res) {
                                console.log(res);
                                message.success('Xabar yuborildi');
                            })
                            .catch(function (error) {
                                console.log(error);
                                message.error('Xabar yuborishda xatolik');
                            });
                    }}>
                        <Form.Item name="text" label="Xabar matnini kiriting">
                            <TextArea rows={6} maxLength={1000}></TextArea>
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                            <Col span={18}>
                                <Form.Item>
                                    <Select
                                        disabled={allShops}
                                        mode="multiple"
                                        allowClear
                                        placeholder="Kerakli do'konni tanlang"
                                        defaultValue={[]}
                                        optionFilterProp="label"
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={handleChange}
                                        options={options}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item>
                                    <Button type="primary" onClick={handleShop}>Barchasini tanlash</Button>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>
                                    <Button type="primary" danger onClick={handleClear}>Tozalash</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button htmlType='submit' type="primary">Yuborish</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
            <div>
                <Card title="Umumiy Ma'lumot berish tugmasi va xabarini taxrirlash"  style={{ marginBottom: 16, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>
                    <BotInfoBtn />
                </Card>
            </div>
        </div>
    );
}

export default BotMessage;