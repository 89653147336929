import { Button, Card, Col, Modal, Row } from 'antd';
import api from 'http';
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useReactToPrint } from "react-to-print";

function Balls(props) {
    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({
        content: () => contentRef.current,
    });
    const [balls, setBalls] = useState([]);
    let [modal, setModal] = useState(false);
    let [cards, setCards] = useState(false);
    let [send, setSend] = useState(false);

    useEffect(() => {
        api.get("get-total-ball")
            .then(function (res) {
                setBalls(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    const DeleteAllBalls = () => {
        api.get("set-zero").then(() => {
            setModal(false);
        });
    };

    return (
        <div style={{overflow: 'auto', height: '84vh'}}>
            <Modal title="Botga habar yuborish" open={send}>
                <p>Haqiqatdan ballarni tiklashni hohlaysizmi?</p>
            </Modal>
            <Modal
                title="Barcha ballarni tozalash"
                open={modal}
                onOk={() => DeleteAllBalls()}
                onCancel={() => setModal(false)}
            >
                <p>Haqiqatdan barcha ballarni tozalashni hohlaysizmi? Keyin ularni qayta tiklash imkoniyati yo'q!!!</p>

            </Modal>
            <Modal
                title="Reklama Kartochkalari"
                open={cards}
                onOk={() => setCards(false)}
                onCancel={() => setCards(false)}
                width={'20cm'}
            >
                <div style={{marginBottom: '16px'}}>
                <Button onClick={() => reactToPrintFn()} type="primary" block>Print</Button>
                </div>
                <div ref={contentRef} style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: "16px" }}>
                    {balls.map((ball) => (
                        <div key={ball.id} style={{ display: "grid", gridTemplateColumns: '2fr 1fr', justifyContent: "space-between", alignItems: "center", border: "1px solid #ccc", padding: "15px" , width: '90mm', height: '50mm', fontSize: '12px', pageBreakInside: 'avoid'}}>
                            <div>
                                <h3 style={{fontWeight: 'bold'}}>{ball.name}</h3>
                                <p><b>Viloyat:</b> : {ball.viloyat_nomi} {ball.tuman_nomi} {ball.manzil}</p>
                                <p><b>Telefon:</b> : {ball.telefon}</p>
                            </div>
                            <div>
                                <QRCode size={100} value={"https://t.me/visolaball_bot?start=" + ball.unique_key} />
                                <b>{ball.unique_key}</b>
                            </div>

                        </div>
                    ))}
                </div>
            </Modal>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                <h1>Ballar</h1>
                <Button type="primary" danger onClick={() => setModal(true)}>
                    Barcha ballarni tozalash
                </Button>
                <Button type="primary" onClick={() => setCards(true)}>
                    Reklama Kartochkalari
                </Button>
            </div>
            <Row gutter={16} wrap={true}>
                {balls.map((ball) => (
                    <Col span={6} key={ball.id} style={{ marginBottom: "16px" }}>
                        <Card title={ball.name} bordered={true} style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
                            <p><b>Manzil:</b> - {ball.viloyat_nomi} {ball.tuman_nomi} {ball.manzil}</p>
                            <p><b>Telefon:</b> - {ball.telefon}</p>
                            <p><b>Balans:</b> - {ball.balans}</p>
                            <p><b>To'plangan Ball:</b> - {ball.total_ball}</p>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default Balls;